import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './CheapCarHire.css';
import { Button } from 'react-bootstrap';
import { CarDataDrop } from './CarDataDrop';
import { Link } from 'react-router-dom';
// import "/src/App.css";

export const CheapCarHire = () => {
    const [slideindex, setSlideindex] = useState(0);

    return (
        <div className='newCheapCarHireDestinationContainer'>
            <div className='newCheapCarHireHeadingContainer'>
                <h2> Select cheap Car Hire Destinations</h2>
            </div>

            <div className='searchCarHireDestinationContext'>
                <p> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio sit aliquid veritatis molestiae. Excepturi, voluptas. Praesentium saepe,
                    deserunt iure ducimus commodi accusantium at deleniti dolorem, impedit magnam veniam delectus perferendis!
                </p>
            </div>

            <Slider className="multiCardSlider"
                dots = {true}
                infinite={true}
                // autoplay =  {true}
                // autoplaySpeed = {1000}
                // speed = {1000}
                slidesToShow={5}
                slidesToScroll={1}
                cssEase={"linear"}
                arrows={false}
                appendDots={dots => <ul>{dots}</ul>}

                customPaging={i => (
                    <div className="ft-slick__dots--custom">
                        <div className="loading" />
                    </div>
                )}
                beforeChange={(current, next) => setSlideindex(next)}
                responsive={[
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 950,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]}
                customPagingg={(i) => (
                    <div
                        style={{
                            width: "100%",
                            top: "-10px",
                            opacity: 0,
                        }}
                    >
                        {i}
                    </div>
                )}
            >
                <Link to="/rentalsCarDetails">
                    <div className="card-wrapper newCheapCarHireWrapper">
                        <div className="card newCheapCarHireImage">
                            <div className="card-image newCheapCarHireCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewRentals/CheapCarHire/dubai.webp" alt='image' />
                            </div>
                            <div className="newCheapCarHireDetails">
                                <h2> Dubai,UAE
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Book your Ride</p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>


                <Link to="/rentalsCarDetails">
                    <div className="card-wrapper newCheapCarHireWrapper">
                        <div className="card newCheapCarHireImage">
                            <div className="card-image newCheapCarHireCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewRentals/CheapCarHire/gurugram.webp" alt='image' />
                            </div>

                            <div className="newCheapCarHireDetails">
                                <h2> Gurugram,Haryana
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Book your Ride </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/rentalsCarDetails">
                    <div className="card-wrapper newCheapCarHireWrapper">
                        <div className="card newCheapCarHireImage">
                            <div className="card-image newCheapCarHireCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewRentals/CheapCarHire/mumbai.webp" alt='image' />
                            </div>

                            <div className="newCheapCarHireDetails">
                                <h2> Mumbai,Maharashtra
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Book your Ride </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/rentalsCarDetails">
                    <div className="card-wrapper newCheapCarHireWrapper">
                        <div className="card newCheapCarHireImage">
                            <div className="card-image newCheapCarHireCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewRentals/CheapCarHire/newyork.webp" alt='image' />
                            </div>

                            <div className="newCheapCarHireDetails">
                                <h2> New York,USA
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Book your Ride  </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/rentalsCarDetails">
                    <div className="card-wrapper newCheapCarHireWrapper">
                        <div className="card newCheapCarHireImage">
                            <div className="card-image newCheapCarHireCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewRentals/CheapCarHire/udaipur.webp" alt='image' />
                            </div>

                            <div className="newCheapCarHireDetails">
                                <h2> Udaipur,Rajasthan
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Book your Ride </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to="/rentalsCarDetails">
                    <div className="card-wrapper newCheapCarHireWrapper">
                        <div className="card newCheapCarHireImage">
                            <div className="card-image newCheapCarHireCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/BungeeJump.jpg" alt='image' />
                            </div>

                            <div className="newCheapCarHireDetails">
                                <h2> New Zealand
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>
            </Slider>

            <CarDataDrop />
        </div >
    )
};
