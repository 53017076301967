import React from 'react'
import './CuriseInnerPageAvlibility.css';
// import { HiOutlineCheckCircle } from "react-icons/hi";

export const CuriseInnerPageAvlibility = () => {
    return (
        <div>
            <div className='CuriseInnerPageAvlibility-maindiv'>
                <div className='CuriseInnerPageAvlibility-innermaindiv'>
                    <h4> Cabins available</h4>
                    <div className='CuriseInnerPageAvlibility-containdiv'>
                        <div className='CuriseInnerPageAvlibility-imagediv'>
                            <h4>Interior standard</h4>
                            <img src='Images/NewHomeLayout/CruiseDetailsPage/CruiseInnerImage1.png' alt='image'></img>
                        </div>
                        <div className='CuriseInnerPageAvlibility-innercontaindiv'>
                            <p>Room size -117 SQ.FT</p>
                            <p>Accommodates up to 4 guest </p>
                            <p>A comfortable budget-friendly room option to enjoy your cruise vacation </p>
                        </div>
                        <div className='CuriseInnerPageAvlibility-containpricediv'>
                            <p>Starting Price</p>
                            <h5>₹ 40,467</h5>
                            <div>
                                <button type="button" class="btn btn-primary CuriseInnerPageAvlibility-booknowbutton2 "> Selected Cabin </button>
                            </div>
                        </div>
                    </div>
                    <div className='CuriseInnerPageAvlibility-containdiv'>
                        <div className='CuriseInnerPageAvlibility-imagediv'>
                            <h4>Interior standard</h4>
                            <img src='Images/NewHomeLayout/CruiseDetailsPage/CruiseInnerImage1.png' alt='image'></img>
                        </div>
                        <div className='CuriseInnerPageAvlibility-innercontaindiv'>
                            <p>Room size -117 SQ.FT</p>
                            <p>Accommodates up to 4 guest </p>
                            <p>A comfortable budget-friendly room option to enjoy your cruise vacation </p>
                        </div>
                        <div className='CuriseInnerPageAvlibility-containpricediv'>
                            <p>Starting Price</p>
                            <h5>₹ 40,467</h5>
                            <div>
                                <button type="button" class="btn btn-primary CuriseInnerPageAvlibility-booknowbutton2 ">Selected Cabin  </button>
                            </div>
                        </div>
                    </div>
                    <div className='CuriseInnerPageAvlibility-containdiv'>
                        <div className='CuriseInnerPageAvlibility-imagediv'>
                            <h4>Interior standard</h4>
                            <img src='Images/NewHomeLayout/CruiseDetailsPage/CruiseInnerImage1.png' alt='image'></img>
                        </div>
                        <div className='CuriseInnerPageAvlibility-innercontaindiv'>
                            <p>Room size -117 SQ.FT</p>
                            <p>Accommodates up to 4 guest </p>
                            <p>A comfortable budget-friendly room option to enjoy your cruise vacation </p>
                        </div>
                        <div className='CuriseInnerPageAvlibility-containpricediv'>
                            <p>Starting Price</p>
                            <h5>₹ 40,467</h5>
                            <div>
                                <button type="button" class="btn btn-primary CuriseInnerPageAvlibility-booknowbutton2 ">Selected Cabin </button>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
        </div>
    )
}
