import React from 'react'
import './ChepestFlight.css';
export const ChepestFlight = () => {
  return (
    <div>
        <div className='ChepestFlightMainDiv'>
        <div className='cheapflightheading1'>
            <p> Book cheap fligts from jaipur to new delhi  </p>
            </div>
        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                        </div>
                        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                        </div>
                        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                        </div>
                        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                        </div>

                        <div> <button className='Flightshowmorebutton'>Show More</button></div>

                        <div className='cheapflightheading1'>
                            <p>Recent one-way flight deal</p>
                        </div>

                        <div>
                        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                        </div>
                        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                        </div>
                        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                        </div>
                        <div className='cheapflightdetails'>
                            <div className='cheapflightdetailsflightlogos'>
                                <img src='Images/Partners Logos/Air India.png'/>
                            </div>
                            <div className='cheapflightnames'>
                                        01:45AM to 04:25PM
                                        <br/>
                                        <p style={{color:"gray"}}>Air India</p>
                            </div>
                            <div className='cheapflightdetailsflightTimming'>
                                        2Hrs  10Minutes
                            </div>
                            <div className='cheapflightdetailsflightStops'>
                                   Non Stop
                            </div>
                            <div className='cheapflightdetailsflightPrice'>
                                    $2500
                                    <button className='cheapflightBooknowButton'>Book Now</button>
                            </div>
                            
                        </div>
                        <div> <button className='Flightshowmorebutton'>Show More</button></div>
                        </div>
        </div>
    </div>
  )
}
