import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useRef } from "react";
import { useEffect } from "react";
import "./NewHomeExclusiveDeals.css";
import backsideImage from "./backsideImage.jpeg";
import dealspics from "./dealspics.png";
import { Button } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import countrydata from '../../../../CountryData.json';
import { NewExclusiveDealsDropdown } from './NewExclusiveDealsDropdown';

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from 'react-router-dom';

export const NewHomeExclusiveDeals = () => {
    const [countryid, setCountryid] = useState('');
    const [state, setState] = useState([]);
    const [stateid, setStateid] = useState('');

    const handlecounty = (e) => {
        const getcountryId = e.target.value;
        const getStatedata = countrydata.find(country => country.country_id === getcountryId).states;
        setState(getStatedata);
        setCountryid(getcountryId);
        //console.log(getcountryId);
    }

    const handlestate = (e) => {
        const stateid = e.target.value;
        //console.log(stateid);
        setStateid(stateid);
    }

    const sliderRef = useRef(null);
    useEffect(() => {
        // console.log(sliderRef);
    }, []);
    return (
        <>
            <div className="newExclusivemaindiv" >
                <div className="newExclusivedealsheadingcontainer">
                    <h2 className="newExclusivedealsheading1">Offers</h2>
                    <div className="newExclusivedealsheading2">
                        <h3>
                            <Link style={{textDecoration:"none",color:"#393939"}} to={"/flights"}> Flights </Link>
                        </h3>

                        <h3>
                            <Link style={{textDecoration:"none",color:"#393939"}} to={"/hotel"}>  Hotels </Link>
                        </h3>

                        <h3>
                            <Link style={{textDecoration:"none",color:"#393939"}} to={"/holidays"}> Holidays </Link>
                        </h3>

                        <h3>
                            <Link style={{textDecoration:"none",color:"#393939"}} to={"/cruise"}> Cruise </Link>
                        </h3>

                        <h3>
                            <Link style={{textDecoration:"none",color:"#393939"}} to={"/rentals"}> Rentals </Link>
                        </h3>

                        <h3>
                            <Link style={{textDecoration:"none",color:"#393939"}} to={"/staycations"}> Staycations </Link>
                        </h3>
                    </div>

                    <div className="viewallexclusivebuttons" >
                        <div className="newExclusivedealsheadingbuttons">
                            <div style={{ display: "flex" }}>
                                <FaAngleLeft size={"2.5em"}
                                    onClick={() => sliderRef.current.slickPrev()}
                                />
                                <FaAngleRight size={"2.5em"}
                                    onClick={() => sliderRef.current.slickNext()}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="newExclusivedealsconatinerdiv">
                    <div className="newExclusivedealsimageheading">
                        <div className='newExclusiveImagesDiv'>
                            <img className="newExclusivedealsimagebackside" src={backsideImage} />
                            <LazyLoadImage className="newExclusivedealsimagefrontside" src={dealspics} />
                        </div>

                        <div className="newExclusivedealsheadings">
                            <p className="newExclusiveheadingsboldheadings">
                               Exclusive Deals
                            </p>
                            <p className='newExclusiveHeadingsLowerHeadings'>
                                Most Visited and Used
                            </p>
                        </div>

                        <div className='newExclusiveDropdownDiv'>
                            <NewExclusiveDealsDropdown />
                        </div>
                    </div>

                    <div className="newExclusivedealssliderdata">
                        <div className="newExclusivedealsmaindivslider">
                            <Slider
                                arrows={false}
                                autoplay={true}
                                speed={2000}
                                autoplaySpeed={2000}
                                dots={true}
                                dotsclassName="slick-dots line-indicator"
                                ref={sliderRef}
                                slidesToShow={2}
                                slidesToScroll={2}
                                rows={2}
                                slidesPerRow={1}
                                responsive={[
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 3,
                                            slidesToScroll: 1,
                                            infinite: true
                                        }
                                    },
                                    {
                                        breakpoint: 950,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 1,
                                            infinite: true
                                        }
                                    },
                                    {
                                        breakpoint: 800,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 1,
                                            infinite: true
                                        }
                                    },
                                    {
                                        breakpoint: 600,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 2,
                                            initialSlide: 2
                                        }
                                    },
                                    {
                                        breakpoint: 480,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1
                                        }
                                    }
                                ]}
                                customPaging={(i) => (
                                    <div
                                        style={{
                                            // position: "absolute",
                                            width: "100%",
                                            top: "-10px",
                                            opacity: 0,
                                        }}
                                    >
                                        {i}
                                    </div>
                                )}
                            >
                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>

                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>

                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>

                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>

                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>

                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>

                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>

                                <div className=" newExclusiveouterdivtop" >
                                        <div className=" newExclusivecardimageDiv">
                                            <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Cruise/ExclusiveCruise/CruiseImage2.jpg" className='newExclusiveDealsImage' alt="newExclusive deals image" />
                                        </div>
                                        <div className="newExclusivecarddescmainDiv">
                                            <h3 className="newExclusivedealscardtitle">
                                                Save Flat Rs. 4500 on your next Holiday.
                                            </h3>
                                            <p className="newExclusivedealscard_desc">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </p>

                                            <button className="newExclusivedealsbutton" variant="primary" > Book Now </button>
                                        </div>
                                </div>
                               


                               
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

