import React from 'react';
import "./DriverDetails.css";

export const DriverDetails = () => {
    return (
        <>
            <div className='driverDetailsMainOuterDiv'>
                <div className='driverDetailsHeading'>
                    <h3> Driver details </h3>
                </div>

                <div className='driverDetailsFormMainOuterDiv'>
                    <form className="row g-3 needs-validation" novalidate>
                        <div class="col-md-6">
                            {/* <h5> First Name </h5> */}
                            <label htmlFor="validationCustom01" className ="form-label driverDetailInputHeading"> First name </label>
                            <input type="text" className ="form-control" id="validationCustom01" placeholder='Sanket' required />
                            <div className ="valid-feedback">
                                Looks good!
                            </div>
                        </div>

                        <div className="col-md-6">
                            {/* <h5> Last Name </h5> */}
                            <label htmlFor="validationCustom02" className="form-label driverDetailInputHeading">Last name</label>
                            <input type="text" className="form-control" id="validationCustom02" placeholder='Parek' required />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                            <label htmlFor="validationCustomUsername" className="form-label">Username</label>
                            <div className="input-group has-validation">
                                <span className="input-group-text" id="inputGroupPrepend">@</span>
                                <input type="text" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                                <div className="invalid-feedback">
                                    Please choose a username.
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <label htmlFor="validationCustom03" className="form-label driverDetailInputHeading"> Mobile Number </label>
                            <input type="text" className="form-control" id="validationCustom03" placeholder='Enter 10 digit mobile number' required />
                            <div className="invalid-feedback">
                                Please provide a valid mobile Number.
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="validationCustom05" className="form-label driverDetailInputHeading"> E-mail </label>
                            <input type="text" className="form-control" placeholder='abc@gmail.com' id="validationCustom05" required />
                            <div className="invalid-feedback">
                                Please provide a valid E-mail.
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="validationCustom04" className="form-label driverDetailInputHeading"> Driver's Age </label>
                            <select className="form-select" id="validationCustom04" required>
                                <option selected disabled value="">Choose...</option>
                                <option> 18-25 </option>
                                <option> 25-35 </option>
                                <option> 35-50 </option>
                                <option> 50-60 </option>
                            </select>
                            <div className="invalid-feedback">
                                Please select a valid age option.
                            </div>
                        </div>


                        <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input " type="checkbox" value="" id="invalidCheck" required />
                                <label className="form-check-label driverDetailInputHeading" htmlFor="invalidCheck">
                                    Agree to terms and conditions
                                </label>
                                <div className="invalid-feedback">
                                    You must agree before submitting.
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <button className="btn btn-primary" type="submit">Submit form</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
