import React, { useState, Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SearchPlaceDestination.css';

import SliderWrapper from "./SlickSliderStyle";
import { Button } from 'react-bootstrap';
import { HotelDataFaq } from './HotelDataFaq';
// import "/src/App.css";


export const SearchPlaceDestination = () => {
    const [slideindex, setSlideindex] = useState(0);

    const settings = {
        // dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        cssEase: "linear",
        customPaging: i => (
            <div className="ft-slick__dots--custom">
                <div className="loading" />
            </div>
        ),

        beforeChange: (current, next) => setSlideindex(next),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='newSearchPlaceDestinationContainer'>
            <div className='newSearchPlaceDestinationHeadingContainer'>
                <h2> Search for places for your stay comfort </h2>
            </div>

            <SliderWrapper>
                <Slider {...settings} className="multiCardSlider">
                    <div className="card-wrapper newSearchPlaceDestinationWrapper">
                        <div className="card newSearchPlaceDestinationImage">
                            <div className="card-image newSearchPlaceDestinationCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/CityMax Hotel.jpg" alt='image' />
                            </div>

                            <div className="newSearchPlaceDestinationDetails">
                                <h2> Citymax Hotel Bru
                                    {/* <span> */}
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Dubai,UAE </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                            AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="card-wrapper newSearchPlaceDestinationWrapper">
                        <div className="card newSearchPlaceDestinationImage">
                            <div className="card-image newSearchPlaceDestinationCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/HolidayInn.jpg" alt='image' />
                            </div>

                            <div className="newSearchPlaceDestinationDetails">
                                <h2> The Signature Inn
                                    {/* <span> */}
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Banglore,Karnataka </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                            AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="card-wrapper newSearchPlaceDestinationWrapper">
                        <div className="card newSearchPlaceDestinationImage">
                            <div className="card-image newSearchPlaceDestinationCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Rambuttri Village.jpg" alt='image' />
                            </div>

                            <div className="newSearchPlaceDestinationDetails">
                                <h2> Rambuttri Village                         {/* <span> */}
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                            AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="card-wrapper newSearchPlaceDestinationWrapper">
                        <div className="card newSearchPlaceDestinationImage">
                            <div className="card-image newSearchPlaceDestinationCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Hii Nyc Hostel.jpg" alt='image' />
                            </div>

                            <div className="newSearchPlaceDestinationDetails">
                                <h2> Hill Nyc Hostel
                                    {/* <span> */}
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> New york,USA </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                            AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="card-wrapper newSearchPlaceDestinationWrapper">
                        <div className="card newSearchPlaceDestinationImage">
                            <div className="card-image newSearchPlaceDestinationCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Kintusgi.webp" alt='image' />
                            </div>

                            <div className="newSearchPlaceDestinationDetails">
                                <h2> Kintusgi
                                    {/* <span> */}
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Bangkok </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                            AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="card-wrapper newSearchPlaceDestinationWrapper">
                        <div className="card newSearchPlaceDestinationImage">
                            <div className="card-image newSearchPlaceDestinationCardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/BungeeJump.jpg" alt='image' />
                            </div>

                            <div className="newSearchPlaceDestinationDetails">
                                <h2> New Zealand
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                    <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                        Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                            AED 3000 </span>
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Slider>
            </SliderWrapper>

            {/* <HotelDataFaq /> */}
        </div>
    );
};
