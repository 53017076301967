import React from 'react';
import "./WhenToBookCar.css";

export const WhenToBookCar = () => {
    return (
        <>
            <div className='rentalsBookOuterDiv'>
                <h3> When to book a rental car in </h3>
                <p> Average daily price per day. </p>
                <img src='Images/Rentals/RidesWeOffer/GRAPH.png' alt='graph image'/>
            </div>
        </>
    )
}
