import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './BestTrendingActivities.css';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";


// import "/src/App.css";

export const NewTrendingActivities = () => {

    const [slideindex, setSlideindex] = useState(0);
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    //     speed: 2000,
    //     slidesToShow: 5,
    //     slidesToScroll: 1,
    //     cssEase: "linear"
    // };

    return (
        <div className='newTrendingAtivitycontainer'>
            <div className='newTrendingActivityHeadingContainer'>
                <h2>
                    Trending Activities
                </h2>
            </div>

            <Slider className="multiCardSlider"
                dots={true}
                infinite={true}
                autoplay={true}
                autoplaySpeed={3000}
                speed={3000}
                slidesToShow={5}
                slidesToScroll={1}
                cssEase={"linear"}
                appendDots={dots => <ul>{dots}</ul>}
                customPaging={i => (
                    <div className="ft-slick__dots--custom">
                        <div className="loading" />
                    </div>
                )}
                beforeChange={(current, next) => setSlideindex(next)}
                responsive={[
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 950,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]}
                customPagingg={(i) => (
                    <div
                        style={{
                            width: "100%",
                            top: "-10px",
                            opacity: 0,
                        }}
                    >
                        {i}
                    </div>
                )}
            >
                <Link to="/activitiesInnerpage">
                    <div className="card-wrapper newWorldActivitywrapper">
                        <div className="card newMultisection_Activityimage">
                            <div className="card-image newWorldActivitycardImage">
                                <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TrendingActivities/bike ride spiti valley ladakh.jpg" alt='image' />
                            </div>

                            <div className="newWorldActivitydetails">
                                <h2> Bike Ride <span className="job-title">

                                    <p style={{ fontSize: "13.5px" }}> All Inclusive Packages </p>
                                    <p style={{ marginTop: "-6%", fontSize: "13px" }}>
                                        Starts @ <h4 style={{ fontWeight: "700", fontSize: "22px" }}>AED 3000</h4>
                                    </p>
                                </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>

                <div className="card-wrapper newWorldActivitywrapper">
                    <div className="card newMultisection_Activityimage">
                        <div className="card-image newWorldActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TrendingActivities/boat ride in italy.jpg" alt='image' />
                        </div>

                        <div className="newWorldActivitydetails">
                            <h2> Boat Ride
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newWorldActivitywrapper">
                    <div className="card newMultisection_Activityimage">
                        <div className="card-image newWorldActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/Float along the Amazon.jpg" alt='image' />
                        </div>
                        <div className="newWorldActivitydetails">
                            <h2> Amazon river
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newWorldActivitywrapper">
                    <div className="card newMultisection_Activityimage">
                        <div className="card-image newWorldActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/Surf The Pipeline in Oahu.jpg" alt='image' />
                        </div>

                        <div className="newWorldActivitydetails">
                            <h2> Surfing
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newWorldActivitywrapper">
                    <div className="card newMultisection_Activityimage">
                        <div className="card-image newWorldActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/ZipLine Over Niagara Falls.jpg" alt='image' />
                        </div>

                        <div className="newWorldActivitydetails">
                            <h2> Zip Line
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newWorldActivitywrapper">
                    <div className="card newMultisection_Activityimage">
                        <div className="card-image newWorldActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/BungeeJump.jpg" alt='image' />
                        </div>

                        <div className="newWorldActivitydetails">
                            <h2> New Zealand
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>
            </Slider>
        </div >
    );
};