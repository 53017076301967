import './HandPickedWeekendDestinationsBanner.css';

export const HandPickedWeekendDestinationsBanner = () => {

    return (
    <>
            <div class="card">
            <img src="Images/SubPagesBackgroundImages/weekendBanner.jpg" alt='banner image'/>
            <div class="card-img-overlay">
                <h5 className='HandpickedWeekendDestinationPagebannerHeading'>The Most Excitting Weekend to Experience the Magic of Nature are a step away.</h5>
                <p style={{color:"white",marginLeft:"5%"}}>“All You need is a good dose of vitamin sea.” Just Have a visit once.</p>
            </div>
            </div>
    </>

    );
}

