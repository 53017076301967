import './HandPickedHillStationsDestinationsBanner.css';

export const HandPickedHillStationsDestinationsBanner = () => {

    return (

        <>
            <div class="card">
            <img src="Images/SubPagesBackgroundImages/hillstations.png" alt='banner image'/>
            <div class="card-img-overlay">
                <h5 className='HandpickedHillStationsDestinationPagebannerHeading'>The Most Excitting Beaches to Experience the Magic of Nature are a step away.</h5>
                <p style={{marginLeft:"5%",color:"white"}}>“All You need is a good dose of vitamin sea.” Just Have a visit once.</p>
            </div>
            </div>
        </>

    );
}

