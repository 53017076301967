import React from 'react';
import "./NewVisaBanner.css";
import { NewVisaBannerForm } from './NewVisaBannerForm';

export const NewVisaPageBanner = () => {
    return (
        <div className='holidayBannerContainer'>
            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner holidayBannerImagesmaindiv">
                    <div class="carousel-item active">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Visa/image1.jpg" class="d-block w-100" alt="banner image" />
                    </div>
                    <div class="carousel-item">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Visa/Image 2.jpg" class="d-block w-100" alt="banner image" />
                    </div>
                    <div class="carousel-item">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Visa/Image 3.jpg" class="d-block w-100" alt="banner image" />
                    </div>
                </div>
            </div>

            <NewVisaBannerForm/>

            <div className='newVisaBannerTexts' >
                <p> Get hassle-free visa services with - 
                    <span>
                        <i> TravelFlap </i>
                    </span>
                </p>
            </div>
        </div>
    );
};
