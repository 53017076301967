import React from "react";
import "./NewHomeStoriesSection.css";
import "./TestComments.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsPlayCircle, BsArrowRight } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
// import { useTransition, animated } from "react-spring";
import { useState, useEffect, useRef } from "react";
import YoutubeLink from "./YoutubeLink";

export const NewHomeStoriesSection = () => {
    const sliderRef = useRef(null);
    useEffect(() => {
        // console.log(sliderRef);
    }, []);

    const [videoString, setVideoString] = useState();
    const [toggle, setToggle] = useState(false);
    const settings = {
        infinite: true,
        accessibility: false,
        // autoplay: true,
        // autoplaySpeed: 1000,
        // speed: 1000,
        slidesToScroll: 1,
        initialSlide: 5,
        draggable: false,
        touchMove: false,
        slidesToShow: 5,
        pauseOnHover: false,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    const arr = [
        {
            BrandName: "ScaleForce",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories1.png",
            name: "Venus Wills",
            role: "Senior Director of Demand Execution",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "Incubeta",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories2.png",
            name: "Linda Farr",
            role: "Chief of Staff",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "Ranking Carolina",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories4.png",
            name: "Tommy Sugishita",
            role: "interective Officer",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "Studio marche",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories5.png",
            name: "Alisha Dunsford",
            role: "Graphic Designer",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "HSE",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories1.png",
            name: "Chani Thompson",
            role: "Nutrition and Health Coach",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "National Geographic",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories2.png",
            name: "Susie murphy",
            role: "Senior Specialist,Audience Selection",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "ScaleForce",
            text: "TravelFlap is a wonderfull team with great efforts and passion,Their Services and communications have have exceeded my expectations.",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories1.png",
            name: "Venus Wills",
            role: "Senior Director of Demand Execution",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "Incubeta",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories4.png",
            name: "Linda Farr",
            role: "Chief of Staff",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "Ranking Carolina",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories5.png",
            name: "Tommy Sugishita",
            role: "interective Officer",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "Studio marche",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories1.png",
            name: "Alisha Dunsford",
            role: "Graphic Designer",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "HSE",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories2.png",
            name: "Chani Thompson",
            role: "Nutrition and Health Coach",
            video: "GEsw-rqC-Hk",
        },
        {
            BrandName: "National Geographic",
            brandImage: "https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewHomeStoriesSection/stories5.png",
            name: "Susie murphy",
            role: "Senior Specialist,Audience Selection",
            video: "GEsw-rqC-Hk",
        },
    ];

    const handleModal = (item) => {
        console.log(item);
        setVideoString(item.video);
        setToggle(true);
    };

    const handleModalOut = () => {
        setToggle(false);
    };

    return (
        <div className="storiesSection">
            <h2> What Our clients says about us </h2>
            <Slider {...settings}>
                {arr.map((item, i) => (
                    <div key={i} className="mainContentDiv">
                        <IconContext.Provider value={{ className: "playIcon" }}>
                            <BsPlayCircle />
                        </IconContext.Provider>
                        <p className="storiesBrandName">{item.BrandName}</p>
                        <p className="testText">"{item.text}"</p>
                        <p className="storiesFloating">{item.BrandName}</p>
                        <div className="storiesContentDiv">
                            <div className="storiesImageDiv">
                                <img src={item.brandImage} alt="image" />
                            </div>
                        </div>

                        <div className="bylineContent">
                            <p> {item.name} </p>

                            <p className="roleFont">
                                {item.role}
                            </p>
                        </div>
                    </div>
                ))}
            </Slider>

            <p className="testimonial">
                View more testimonials
                <IconContext.Provider value={{ className: "rightArrow" }}>
                    <BsArrowRight />
                </IconContext.Provider>
            </p>
        </div>
    );
};

