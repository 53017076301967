import React from 'react';
import { NewStaycationTopOptionSlider } from './NewStaycationTopOptionSlider/NewStaycationTopOptionSlider';
import "./NewStaycationTopSlider.css";
import { StaycationTopCardSLider } from './StaycationTopCardSlider/StaycationTopCardSlider';
import { BsSearch } from "react-icons/bs";

export const NewStaycationTopSlider = () => {
    return (
        <>
            <div className='newStaycationTopSliderOuterDiv'>

                <div className='newStaycationTopSliderHeadingMainDiv'>
                    <h3>
                    Whether you're looking for an adventure or a getaway, there are many interesting places to visit in the staycation community. Whether it's a new destination, an old favorite, or somewhere new to explore, there's sure to be something that will catch your eye and keep you entertained.
                    </h3>

                    <div className='newStaycationHeadingInnerDiv'>

                        <div className='newStaycationOptionSliderTopSearchDiv'>
                            <div className='newStaycationsOptionSliderNameInput'>
                                <div className='newStaycationsOptionsSliderinputNameContainer'>
                                    <BsSearch size={"1.5em"} style={{ color: "black", marginLeft: "4%", fontSize: "18px", marginTop: "3%" }} />
                                    <input type="text" placeholder='Search for Destination' />
                                </div>

                            </div>
                        </div>

                        <div className='newStaycationTopOptionsDiv'>
                            <NewStaycationTopOptionSlider />
                        </div>
                    </div>
                </div>

                <StaycationTopCardSLider />
            </div>
        </>
    );
};
