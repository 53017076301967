import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./OurPartners.css";
export default class OurPartners extends Component {
    render() {
        const settings = {
            infinite: true,
            speed: 1000,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows : false,
            cssEase: "linear",
        };
        return (
            <div className="_maincarouseldiv">
                <Slider {...settings}>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/6.png" className="_imagediv" alt="carouselimage" />
                        <div className="_hoverdiv">
                        </div>
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/8.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/7.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/9.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/10.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/11.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/12.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/13.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                    <div className="_carouseldiv1">
                        <img src="https://blueberry-travel.com/src/img/brands/1.png" className="_imagediv" alt="carouselimage" />
                        
                        
                    </div>
                </Slider>
            </div>
        );
    }
}
