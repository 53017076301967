import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './NewTopCityActivity.css';
import { Button } from 'react-bootstrap';
// import "/src/App.css";

export const NewTopCityActivities = () => {
    const [sliderRef, setSliderRef] = useState(null)


    return (
        <div className='newTopCityAtivitycontainer'>
            <div className='newTopCityHeadingContainer'>
                <h2>
                    Top City Activities
                </h2>
            </div>

            <Slider className="multiCardSlider"
                dots={true}
                arrows={false}
                autoplay={true}
                speed={2000}
                autoplaySpeed={2000}
                dotsclassName="slick-dots line-indicator"
                ref={setSliderRef}
                slidesToShow={4}
                slidesToScroll={2}
                responsive={[
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 950,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]}
                customPaging={(i) => (
                    <div
                        style={{
                            width: "100%",
                            top: "-10px",
                            opacity: 0,
                        }}
                    >
                        {i}
                    </div>
                )}
            >
                <div className="card-wrapper newTopCityActivitywrapper">
                    <div className="card newTopCity_Activityimage">
                        <div className="card-image newTopCityActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/Boat under Iguazu Falls.jpg" alt='image' />
                        </div>

                        <div className="newTopCityActivitydetails">
                            <h2> Boating
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newTopCityActivitywrapper">
                    <div className="card newTopCity_Activityimage">
                        <div className="card-image newTopCityActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/Swim Through Mexico;s Cenotes.jpg" alt='image' />
                        </div>

                        <div className="newTopCityActivitydetails">
                            <h2> Swimming
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newTopCityActivitywrapper">
                    <div className="card newTopCity_Activityimage">
                        <div className="card-image newTopCityActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/Float along the Amazon.jpg" alt='image' />
                        </div>
                        <div className="newTopCityActivitydetails">
                            <h2> Amazon river
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newTopCityActivitywrapper">
                    <div className="card newTopCity_Activityimage">
                        <div className="card-image newTopCityActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/Surf The Pipeline in Oahu.jpg" alt='image' />
                        </div>

                        <div className="newTopCityActivitydetails">
                            <h2> Surfing
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newTopCityActivitywrapper">
                    <div className="card newTopCity_Activityimage">
                        <div className="card-image newTopCityActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/Zipline Over Niagara Falls.jpg" alt='image' />
                        </div>

                        <div className="newTopCityActivitydetails">
                            <h2>Zip Line                         {/* <span> */}
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-wrapper newTopCityActivitywrapper">
                    <div className="card newTopCity_Activityimage">
                        <div className="card-image newTopCityActivitycardImage">
                            <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/worldActivitites/BungeeJump.jpg" alt='image' />
                        </div>

                        <div className="newTopCityActivitydetails">
                            <h2> New Zealand
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                    Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                </p>
                            </h2>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};
