import React from 'react';
// import { NewHolidayBannerForm } from './NewHolidayBannerForm';
import "./NewFlightBanner.css";

import { NewFlightBannerForm } from './NewFlightBannerForm';
import {LazyLoadImage} from "react-lazy-load-image-component";

export const NewFlightsBanner = () => {
    return (
        <div className='holidayBannerContainer'>
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner holidayBannerImagesmaindiv">
                    <div className="carousel-item active">
                        <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Flight/FlightsBanner.png" className="d-block w-100" alt="flights banner image1" />
                        {/* <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Flight/Images 1.jpg" className="d-block w-100" alt="flights banner image1" /> */}
                    </div>

                    <div className="carousel-item">
                        <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Flight/Images 2.jpg" className="d-block w-100" alt="flights banner image 2" />
                    </div>

                    <div className="carousel-item">
                        <LazyLoadImage src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Flight/images 3.jpg" className="d-block w-100" alt="fligts banner image 3" />
                    </div>
                </div>
            </div>

            {/* <NewHolidayBannerForm/> */}

            <NewFlightBannerForm/>
            
            <div className='newFlightsBannerTexts' >
                <p> Travelflap lifts your -
                    <span>
                        <i> Vacation Mood </i>
                    </span>
                    
                </p>

            </div>
        </div>
    );
};
