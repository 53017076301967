import React from 'react';
import "./RideWithTravelFlap.css";

export const RideWIthTravelFlap = () => {
    return (
        <>
            <div className='rideWithsMainOterDiv'>

                <div className='rideWithHEadingsDiv'>
                    <h3>
                        Ride with TravelFlap
                    </h3>
                </div>

                <div className='rideWithCardsOuterDiv'>
                    <div className="card rideWithInnerImageDiv">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Rentals/MainPageImages/Car.png" className="card-img-top carImage" alt="ride offer image" />
                        <div className="card-body offeredServicesCarContentFiv">
                            <h5 className="card-title"> Car </h5>
                            <p className='card-text'> Budget Friendly </p>
                        </div>
                    </div>

                    <div className="card rideWithInnerImageDiv">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Rentals/MainPageImages/Auto.png" className="card-img-top autoImage" alt="ride offer image" />
                        <div className="card-body offeredServicesContentFiv">
                            <h5 className="card-title"> Auto </h5>
                            <p className='card-text'> Budget Friendly </p>
                        </div>
                    </div>

                    <div className="card rideWithInnerImageDiv">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Rentals/MainPageImages/Scooter.png" className="card-img-top scooterImage" alt="ride offer image" />
                        <div className="card-body offeredServicesContentFiv">
                            <h5 className="card-title"> Bike </h5>
                            <p className='card-text'> Budget Friendly </p>
                        </div>
                    </div>

                    <div className="card rideWithInnerImageDiv">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Rentals/MainPageImages/Bus.png" className="card-img-top busImage" alt="ride offer image" />
                        <div className="card-body offeredServicesContentFiv">
                            <h5 className="card-title"> Bus </h5>
                            <p className='card-text'> Budget Friendly </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
