import React from 'react'
import './TravelGuideBanner.css';

export const TravelGuideBanner = () => {
  return (
    <div>
      <div className='card destiBannerDiv '>
        <img className='destiBannerimage' src="Images/NewHomeLayout/TourGuide/bannerimage.png" alt='banner image' />
        <div class="card-img-overlay ">
          <p className='travelguideBannerHeading'>Your Imagination Is Your Only Limit </p>
          <div className='vaccy1'><p>Exprience a journey full of adventures with us  </p></div>

        </div>

      </div>
    </div>
  )
}
