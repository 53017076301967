import React from 'react';
import { NewHolidayBannerForm } from './NewHolidayBannerForm';
import "./NewHolidaysPageBanner.css";

export const NewHolidaysPageBanner = () => {
    return (
        <div className='holidayBannerContainer'>
            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner holidayBannerImagesmaindiv">
                    <div class="carousel-item active">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Holiday/Image2.jpg" class="d-block w-100" alt="holiday banner images" />
                    </div>
                    <div class="carousel-item">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Holidayhttps://travel-flap.s3.ap-south-1.amazonaws.com/Images1.jpg" class="d-block w-100" alt="holiday banner images" />
                    </div>
                    <div class="carousel-item">
                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Holidayhttps://travel-flap.s3.ap-south-1.amazonaws.com/Images3.jpg" class="d-block w-100" alt="holiday banner images" />
                    </div>
                </div>
            </div>

            <NewHolidayBannerForm />

            <div className='newHolidayBannerTexts' >
                <p> Plan your holiday trip with
                    <span>
                        <i>TravelFlap</i>
                    </span>
                </p>

            </div>
        </div>
    )
}
