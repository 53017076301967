import { useContext, createContext, useEffect, useState } from "react";
import "./Login.css";
import { Link } from 'react-router-dom';

import { auth, google, facebook, twitter, github } from '../firebase';
import { signInWithPopup, signOut } from 'firebase/auth';
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";

import Home from "../Home";
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export const LoginPageTravelFlap = () => {

    // This part is from the same project
    const [isLogin, setIsLogin] = useState(false);
    const [user, setUser] = useState(null);

    const LoginTrue = () => (
        <>
            <h1>Welcome!</h1>
            <img src={user.photoURL} style={{ width: 120 }} />
            <p>
                Welcome {user.displayName}! Your account {user.email} has been successfully logged in at {user.metadata.lastSignInTime}
            </p>
            <button style={{ width: 150 }} onClick={logout}>
                Logout
            </button>
        </>
    )

    const login = async (provider) => {
        const result = await signInWithPopup(auth, provider)
        setUser(result.user)
        setIsLogin(true)
        console.log(result)
    }

    const logout = async () => {
        const result = await signOut(auth)
        setUser(null)
        setIsLogin(false)
        console.log(result)
    }

    return (


        <>
            <div className='travelFlaploginPageOuterDiv'>

                {/* <NewHomeNav /> */}

                <div className='travelFlaploginPageParentDiv'>
                    {/* <h4> Welcome to thee loginPage Page </h4> */}
                    <div className='travelFlaploginPageInnerDiv'>
                        <div className='travelFlaploginPageFormDiv'>

                            <h3> LoginPage </h3>

                            <div className='loginPageSocialIcons'>
                                {/* <img  onClick={() => login(google)} src='https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/LoginSignup/socialoginoptions.png' alt='social icons' /> */}
                                <FcGoogle onClick={() => login(google)} style={{ fontSize: "25px", marginRight: "4%", marginTop: "0.7%" }} />
                                <img onClick={() => login(facebook)} src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/LoginSignup/facebook.png" style={{ height: "23px" }} alt="image" />
                                {/* <BsFacebook onClick={() => login(facebook)}/> */}
                                <p> or </p>
                            </div>

                            <form className="row g-4 needs-validation  loginPageFormInnerDiv" novalidate>
                                <div className="col-md-10">
                                    {/* <label for="validationCustom01" className="form-label">First name</label> */}
                                    <div className='input-group loginPageNameDiv'>
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <img src='https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/LoginSignup/email.png' alt='icon image' />
                                        </span>
                                        <input type="text" className="form-control" id="validationCustom01" placeholder='Your name' required />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-10">
                                    <div className='input-group loginPagePasswordDiv'>
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <img src='https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/LoginSignup/Password.png' alt='icon image' />
                                        </span>
                                        <input type="password" className="form-control" id="validationCustom03" placeholder='Password' required />
                                        <div className="invalid-feedback">
                                            Please provide a valid city.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button className="btn btn-primary loginPageSubmitButtonDiv" type="submit">Submit form</button>
                                </div>

                                <div className="col-12 loginPageAlradyAccountDiv">
                                    <p className='loginPageRouteText'> Don't have account? </p>

                                    <Link to="/signupPage">
                                        <p className='loginPageRouteButton'> SignUp </p>
                                    </Link>
                                </div>

                            </form>
                        </div>

                        <div className='travelFlaploginPageImageDiv'>
                            <img src='https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/LoginSignup/loginsignuptextimage.png' alt='loginPage image' />
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};
