import React from 'react';
import "./VisaSteps.css";

export const VisaSteps = () => {
    return (
        <div>
            <div className='newVisaSteps'>
                <h5>Wanna Get your visa follow these Easy Steps </h5>
                <img src='Images/InnerVisaPage/Visa (3).png' alt='easy visa steps image' />
            </div>
        </div>
    )
}
