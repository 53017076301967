
import React, { useState } from 'react';
import "./VarietyOfStays.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleDown } from "react-icons/fa";
import countrydata from '../../../../CountryData.json';
import { Link } from 'react-router-dom';
import SliderWrapper from "./SlickSliderStyle";

export const VarietyOfStays = () => {
    const [countryid, setCountryid] = useState('');
    const [state, setState] = useState([]);
    const [stateid, setStateid] = useState('');
    const [slideindex, setSlideindex] = useState(0);

    const handlecounty = (e) => {
        const getcountryId = e.target.value;
        const getStatedata = countrydata.find(country => country.country_id === getcountryId).states;
        setState(getStatedata);
        setCountryid(getcountryId);
        //console.log(getcountryId);
    }

    const handlestate = (e) => {
        const stateid = e.target.value;
        //console.log(stateid);
        setStateid(stateid);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Get Country id" + countryid + " And " + stateid);
    }

    const settings = {
        dots: true,
        dotsclassName: "slick-dots line-indicator",
        infinite: true,
        // autoplay: true,
        autoplayspeed: 1000,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        cssEase: "linear",
        arrows: false,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots--custom">
                <div className="loading" />
            </div>
        ),

        beforeChange: (current, next) => setSlideindex(next),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='newVarietyOfStaysContainer'>
            <div class="newVarietyOfStaysMaincardDiv card text-bg-dark">
                <div class="card-img-overlay">
                    <div className='newVarietyOfStaysMainheading'>
                        <div className='newVarietyOfStaysHeadingouterdiv'>
                            <h2> Explore Variety of Stays </h2>

                            <div id='hotelsVarietyOfStaysDropContainer'>
                                <select id="hotelsVarietyOfStaysCountrydrop" onChange={(e) => handlecounty(e)}
                                    style={{ width: "100%", appearance: "none" }}>
                                    <option value=""> Country </option>
                                    {
                                        countrydata.map((getcountry, index) => (
                                            <option value={getcountry.country_id} key={index}>{getcountry.country_name}</option>
                                        ))
                                    }
                                </select>

                                <div id='hotelsVarietyStaysIcondiv'>
                                    <FaAngleDown size={"1em"} id="hotelsVarietyStaysicon" />
                                </div>
                            </div>
                        </div>

                        {/* <div className='varietyButtonsSelector'>
                            <div class="btn-group varietyButtonMainDiv" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" class="btn-check " name="btnradio" id="btnradio1" autocomplete="off" />
                                <label class="btn btn-outline-primary varietyButton1" htmlFor="btnradio1">Mountain Hotel</label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
                                <label class="btn btn-outline-primary varietyButton2" htmlFor="btnradio2"> Dessert Hotel </label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" />
                                <label class="btn btn-outline-primary varietyButton3" htmlFor="btnradio3"> Camping Site </label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off" />
                                <label class="btn btn-outline-primary varietyButton4" htmlFor="btnradio4"> Lake Front Hotel </label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off" />
                                <label class="btn btn-outline-primary varietyButton5" htmlFor="btnradio4"> Beach Hotel </label>
                            </div>
                        </div> */}

                        {/* From here the next slider part starts */}
                        <div className='newVarietyOfStaysSlidercontainer'>
                            <div className='newVarietyOfStaysSlickslidercontainer'>
                                <SliderWrapper>
                                    <Slider {...settings} className="multiCardSlider">
                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Vanandhaara.webp" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Vanandhaara
                                                        {/* <span> */}
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Karnatak,India </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Abu Dhabi </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Mun ki reti.webp" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Mun Ki Reti
                                                        {/* <span> */}
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Rishikesh,India </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Epupa Falls </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Kintusgi.webp" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Kintusgi Hotel
                                                        {/* <span> */}
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Seetla,India </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Las Vegas </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Aida Villa.webp" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Aida Villa                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Anachal Kerala, India </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Sydney </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/HotelLalit.jpg" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Hotel Lalit
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,India </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Toronto </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/landon.jpg" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> London
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> London </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/denmark.jpg" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Aarhus
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Aarhus </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/zambiaafrica.jpg" />
                                                </div>
                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Zambia
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Zambia </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newVarietyOfStaysYouDiv">
                                                <div className="card-image newVarietyOfStaysCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/swedan.jpg" />
                                                </div>

                                                <div className="newVarietyOfStaysDetails">
                                                    <h2> Stockholm
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}>
                                                                AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Stockholm </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>
                                    </Slider>
                                </SliderWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};
