
import React, { useState } from 'react';
import "./StaycationTopCardSlider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import countrydata from '../../../../../CountryData.json';
import { Link } from 'react-router-dom';
import SliderWrapper from "./SlickSliderStyle";

export const StaycationTopCardSLider = () => {
    const [countryid, setCountryid] = useState('');
    const [state, setState] = useState([]);
    const [stateid, setStateid] = useState('');
    const [slideindex, setSlideindex] = useState(0);

    const handlecounty = (e) => {
        const getcountryId = e.target.value;
        const getStatedata = countrydata.find(country => country.country_id === getcountryId).states;
        setState(getStatedata);
        setCountryid(getcountryId);
        //console.log(getcountryId);
    }

    const handlestate = (e) => {
        const stateid = e.target.value;
        //console.log(stateid);
        setStateid(stateid);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Get Country id" + countryid + " And " + stateid);
    }

    const settings = {
        dots: true,
        dotsclassName: "slick-dots line-indicator",
        infinite: true,
        autoplay: true,
        autoplayspeed: 2000,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        rows: 2,
        cssEase: "linear",
        arrows: false,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots--custom">
                <div className="loading" />
            </div>
        ),
        beforeChange: (current, next) => setSlideindex(next),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='newStaycationTopCardSliderContainer'>

            <div class="newStaycationTopCardSliderMaincarddiv card text-bg-dark">
                {/* <div className='newStaycationTopCardSliderbelowdiv'>
                </div> */}
                <div class="card-img-overlay ">
                    <div className='newStaycationTopCardSliderMainheading'>

                        <div className='newStaycationTopCardSliderSlidercontainer'>
                            {/* From here the next slider part starts */}

                            <div className='newStaycationTopCardSliderSlickslidercontainer'>
                                <SliderWrapper>
                                    <Slider {...settings} className="multiCardSlider">

                                        <Link to="/staycationBookingPage" style={{ marginBottom: "5%" }}>

                                            <div className="card-wrapper">
                                                <div className="card newStaycationTopCardSliderDiv">
                                                    <div className="card-image newStaycationTopCardSliderCardimage">
                                                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/Rambagh Hotel.jpg" alt='image' />
                                                    </div>
                                                    <div className="newStaycationTopCardSliderDetails">
                                                        <h2> Jibhi
                                                            {/* <span> */}
                                                            <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur, Rajasthan</p>
                                                            <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                            <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                                Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                            </p>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/HotelLalit.jpg" alt='image' />
                                                </div>

                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2>
                                                        Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/TajHotel.jpeg" alt='image' />
                                                </div>

                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2> Jibhi
                                                        {/* <span> */}
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur, Rajasthan</p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/HolidayInn.jpg" alt='image' />
                                                </div>

                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2> Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Hotels/AllCompImages/HotelLalit.jpg" alt='image' />
                                                </div>

                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2> Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan  </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/landon.jpg" alt='image' />
                                                </div>

                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2>
                                                        Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/denmark.jpg" alt='image' />
                                                </div>

                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2>
                                                        Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/zambiaafrica.jpg" alt='image' />
                                                </div>
                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2>
                                                        Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/swedan.jpg" alt='image' />
                                                </div>
                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2>
                                                        Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newStaycationTopCardSliderDiv">
                                                <div className="card-image newStaycationTopCardSliderCardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/swedan.jpg" alt='image' />
                                                </div>

                                                <div className="newStaycationTopCardSliderDetails">
                                                    <h2>
                                                        Jibhi
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Jaipur,Rajasthan </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </SliderWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
