import React from 'react';
import "./WayToRentCar.css";

export const WayToRentCar = () => {
    return (
        <>
            <div className='wayToRentCarMainDiv'>
                <div class="card text-bg-dark wayToRentCarInnerDiv">
                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/Rentals/MainPageImages/Comp1Image.png" class="card-img" alt="image" />

                    <div class="card-img-overlay rentCarContentDiv">
                        <h5 class="card-title"> Easy and Fast way to rent your car </h5>
                        <p class="card-text"> Find the one that best fits your needs and budget </p>
                    </div>
                </div>
            </div>
        </>
    )
}
