import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./NewHotelBanner.css";
import { NewHotelCarouselForm } from './NewHotelBannerForm';

export const NewHotelCaruosel = () => {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };

    return (
        <div id='newHotelbanner_mainsection'>

            {/* This is for the Navbar section */}

            {/* This is for the Carousel */}
            <div className='newHotelbanner_main' data-interval="false">

                {/* <Services /> */}
                <Carousel className='newHotelbanner_mycarousel' data-interval="false" slide={true}>

                    <Carousel.Item>
                        <div className='newHotelbannerimage'>

                            <img
                                className="newHotelbannervideo d-block"
                                // src="Images/NewHomeLayout/NewHotelBanner/NewHotelBanner.gif"
                                src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Hotels/Image 3.jpg" 
                                alt="First slide"
                            />
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className='newHotelbannerimage'>

                            <img
                                className="newHotelbannervideo d-block"
                                // src="Images/NewHomeLayout/NewHotelBanner/NewHotelBanner.gif"
                                src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Hotels/Image 2.jpg" 
                                alt="First slide"
                            />
                            
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className='newHotelbannerimage'>
                            <img
                                className="newHotelbannervideo d-block"
                                // src="Images/NewHomeLayout/NewHotelBanner/NewHotelBanner.gif"
                                src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/Banners/Hotels/Image 1.jpg" 
                                alt="First slide"
                            />
                        </div>
                    </Carousel.Item>
                </Carousel>

                <NewHotelCarouselForm />

            </div>

            <div className="newHotel_bannertitle">
                <p> Your comfort is our priority at -
                    <span>
                        <i>TravelFlap</i>
                    </span>
                    {/* Today. */}
                </p>
            </div>
        </div>
    );
};





