import React from 'react'
import { FooterLinks } from '../../FooterLinks/FooterLinks'
import { Plan } from '../../NextHolidaysPlan/Plan'
import { NewHomeAppDownload } from '../NewHomePage/NewHomeAppDownload/NewHomeAppDownload'
import { NewHomeCaruosel } from '../NewHomePage/NewHomeBanner/NewHomeBanner'
import { NewHomeExclusiveDeals } from '../NewHomePage/NewHomeExclusiveDeals/NewHomeExclusiveDeals'
import { NewHomeFooter } from '../NewHomePage/NewHomeFooter/NewHomeFooter'
import { NewHomeHandPicked } from '../NewHomePage/NewHomeHandPicked/NewHomeHandPicked'
import { NewHomeNav } from '../NewHomePage/NewHomeNavbar/NewHomeNavbar';
import { NewDomesticFlightsNearYou } from './NewDomesticFlights/NewDomesticFlights'
import { NewFlightsBanner } from './NewFlightBanner/NewFlightBanner'
import { FrequentlyAskedQuestions } from './NewFrequentlyAskedQuestions/FrequentlyAskedQuestions'
import NewHomeAwards from "../NewHomePage/NewHomeAwards/NewHomeAwards";
import { NewHomeBottomBar } from '../NewHomePage/NewHomeBottomBar/NewHomeBottomBar'
import { FlightsNearYou } from './NewFlightNearYou/FlightsNearYou'

import { NewHomeStoriesSection } from '../NewHomePage/NewHomeStoriesSection/NewHomeStoriesSection';
import { CheapFlights } from './NewCheapFlight/CheapFlights';
import { CenterMode } from '../NewHomePage/NewHomePopularDetination/NewHomePopularDestination';
import { Helmet } from 'react-helmet'

export const NewFlight = () => {
    return (
        <div>
            <Helmet>
                <title> Flight Bookings: Book Cheap Flights and Air Tickets | TravelFlap </title>
                <meta name='description' content='Save money on airfare by searching for cheap flights on TravelFlap. 
                TravelFlap gives you best flight deals on hundreds of airline tickets sites to find you the best prices.' />
            </Helmet>
            <NewHomeNav />

            <NewFlightsBanner />

            <div className='carousel'>
                <NewHomeExclusiveDeals />
            </div>

            <FlightsNearYou />

            <CheapFlights />
            <NewHomeHandPicked />
            <CenterMode />

            <NewHomeAppDownload />

            <FooterLinks />
            <Plan />
            <NewHomeStoriesSection />
            <NewHomeFooter />
            {/* <NewHomeBottomBar /> */}
        </div>
    )
}
