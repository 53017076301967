import './FlightInnerPageBanner.css';

export const FlightInnerPageBanner = () => {

    return (

        <>
            <div className= 'card BannerDiv'>
               <img  className='Bannerimage'  src="Images/NewHomeLayout/Banners/Flighthttps://travel-flap.s3.ap-south-1.amazonaws.com/Images 1.jpg" alt='banner image'/>
            </div>
        </>

    );
}

