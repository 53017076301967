
import React, { useState } from 'react';
import "./NewPopularDestination.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from 'react-bootstrap';
import { MdLocationCity } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";
import countrydata from '../../../../CountryData.json';
import { Link } from 'react-router-dom';
import SliderWrapper from "./SlickSliderStyle";

export const NewPopularDestination = () => {
    const [countryid, setCountryid] = useState('');
    const [state, setState] = useState([]);
    const [stateid, setStateid] = useState('');
    const [slideindex, setSlideindex] = useState(0);

    const handlecounty = (e) => {
        const getcountryId = e.target.value;
        const getStatedata = countrydata.find(country => country.country_id === getcountryId).states;
        setState(getStatedata);
        setCountryid(getcountryId);
        //console.log(getcountryId);
    }

    const handlestate = (e) => {
        const stateid = e.target.value;
        //console.log(stateid);
        setStateid(stateid);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("Get Country id" + countryid + " And " + stateid);
    }

    const settings = {
        dots: true,
        dotsclassName: "slick-dots line-indicator",
        infinite: true,
        autoplay: true,
        autoplayspeed: 1000,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        cssEase: "linear",
        arrows: false,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots--custom">
                <div className="loading" />
            </div>
        ),
        beforeChange: (current, next) => setSlideindex(next),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className='newPopularDestinationsContainer'>

            <div class="newPopularDestinationMaincarddiv card text-bg-dark">
                {/* <img src="Images/NewHomeLayout/BackgroundImages/TopCitiesBackground.jpeg" class="card-img" alt="..." /> */}
                <div className='newPopularbelowdiv'>

                </div>
                <div class="card-img-overlay">
                    <div className='newPopularDestinationsmainheading'>

                        <div className='newPopularDestinationsHeadingouterdiv'>
                            <h2> Top Cities To Explore </h2>

                            <div id='HolidaysPopularDestinationDropContainer'>
                                <select id="HolidaysPopularDestinationCountrydrop" onChange={(e) => handlecounty(e)}
                                    style={{ width: "100%", appearance: "none" }}>
                                    <option value="">Country</option>
                                    {
                                        countrydata.map((getcountry, index) => (
                                            <option value={getcountry.country_id} key={index}>{getcountry.country_name}</option>
                                        ))
                                    }
                                </select>

                                <div id='HolidaysPopularDestinationicondiv'>
                                    <FaAngleDown size={"1em"} id="HolidaysPopularDestinationicon" />
                                </div>
                            </div>

                        </div>

                        <div className='newPopularbelowselectdiv'>
                            <div class="btn-group newPopularbelowselectdivbuttons" role="group" aria-label="Basic radio toggle button group">

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" />
                                <label class="btn btn-outline-primary internationalButton" htmlFor="btnradio3"> International Destinations </label>

                                <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off" />
                                <label class="btn btn-outline-primary DomesticButton" htmlFor="btnradio4"> Domestic Destinations</label>
                            </div>
                            {/* <h5 className='belowInternationalheading'>
                              <Link to="/internationalHolidayssubpage" style={{textDecoration:"none",color:"#393939"}}> International </Link>
                            </h5>
                            <h5 className='belowDomesticHeading'>
                              <Link to="/domesticHolidays" style={{textDecoration:"none",color:"#393939"}}> Domestic </Link>
                            </h5> */}
                        </div>

                        <div className='newPopularDestinationsSlidercontainer'>
                            {/* From here the next slider part starts */}

                            <div className='newPopularDestinationsslickslidercontainer'>
                                <SliderWrapper>
                                    <Slider {...settings} className="multiCardSlider">
                                        <Link to="/holidaysOffers">
                                            <div className="card-wrapper">
                                                <div className="card newPopularDestinationsdiv">
                                                    <div className="card-image newPopularDestinationscardimage">
                                                        <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/abudhabhi.jpg" alt='image' />
                                                    </div>

                                                    <div className="newPopularDestinationsdetails">
                                                        <h2> Abu Dhabi
                                                            <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                                            <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                                            <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                                Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span> \
                                                            </p>
                                                        </h2>
                                                    </div>
                                                </div>

                                                <div className='newhomeTopCitiesBottomTexts'>
                                                    <h2> Abu Dhabi </h2>
                                                    <p className="job-title"> "Best Holiday trip Ever" </p>
                                                </div>
                                            </div>
                                        </Link>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/africa1.jpg" alt='image' />
                                                </div>
                                                <div className="newPopularDestinationsdetails">
                                                    <h2>
                                                        Epupa Falls
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Epupa Falls </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/america.jpg" alt='image' />
                                                </div>
                                                <div className="newPopularDestinationsdetails">
                                                    <h2>
                                                        Las Vegas
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Las Vegas </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/australia.jpg" alt='image' />
                                                </div>

                                                <div className="newPopularDestinationsdetails">
                                                    <h2>
                                                        Sydney
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Sydney </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/canada.jpg" alt='image' />
                                                </div>

                                                <div className="newPopularDestinationsdetails">
                                                    <h2> Toronto
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Toronto </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/landon.jpg" alt='image' />
                                                </div>
                                                <div className="newPopularDestinationsdetails">
                                                    <h2> London
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}>Tourist Visa</p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}>All Inclusive Packages</p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> London </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/denmark.jpg" alt='image' />
                                                </div>
                                                <div className="newPopularDestinationsdetails">
                                                    <h2> Aarhus
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Aarhus </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/zambiaafrica.jpg" alt='image' />
                                                </div>
                                                <div className="newPopularDestinationsdetails">
                                                    <h2> Zambia
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Zambia </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>

                                        <div className="card-wrapper">
                                            <div className="card newPopularDestinationsdiv">
                                                <div className="card-image newPopularDestinationscardimage">
                                                    <img src="https://travel-flap.s3.ap-south-1.amazonaws.com/Images/TopCities/swedan.jpg" alt='image' />
                                                </div>

                                                <div className="newPopularDestinationsdetails">
                                                    <h2>
                                                        Stockholm
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> Tourist Visa </p>
                                                        <p style={{ fontSize: "13.5px", fontWeight: "100" }}> All Inclusive Packages </p>
                                                        <p style={{ marginTop: "-6%", fontSize: "15px", fontWeight: "100" }}>
                                                            Starts @  <span style={{ fontWeight: "700", fontSize: "20px" }}> AED 3000 </span>
                                                        </p>
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className='newhomeTopCitiesBottomTexts'>
                                                <h2> Stockholm </h2>
                                                <p className="job-title"> "Best Holiday trip Ever" </p>
                                            </div>
                                        </div>
                                    </Slider>
                                </SliderWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
