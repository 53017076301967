import React from 'react';
import "./HowRentalsWork.css";

export const HowRentalsWork = () => {
    return (
        <>
            <div className='howRentalsWorkouterdiv'>
                <div className='howRentalsWorkmaindiv'>
                    <h2>
                        How Rentals Work
                    </h2>
                </div>

                <div className='howRentalsWorkImagediv'>
                    <img src='https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewRentals/HowRentalsWork.png' alt='image' />
                </div>
            </div>

            <div className='trustTravelFlapMaindiv'>
                <div className='trustTravelFlapcontentdiv'>
                    <h2 className='trustTravelFlapHeading'>
                        Trust TravelFlap
                    </h2>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo iste, maiores eum deleniti
                        suscipit saepe commodi ex possimus, optio praesentium.
                    </p>
                </div>

                <div className='trustTravelFlapImagediv'>
                    <img src='https://travel-flap.s3.ap-south-1.amazonaws.com/Images/NewHomeLayout/NewRentals/TrustTravelFlap.png' alt='image' />
                </div>
            </div>
        </>
    );
};
